exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hospitals-performing-avneo-js": () => import("./../../../src/pages/hospitals-performing-avneo.js" /* webpackChunkName: "component---src-pages-hospitals-performing-avneo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-doctor-stories-js": () => import("./../../../src/templates/page-doctor-stories.js" /* webpackChunkName: "component---src-templates-page-doctor-stories-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-patient-stories-js": () => import("./../../../src/templates/page-patient-stories.js" /* webpackChunkName: "component---src-templates-page-patient-stories-js" */),
  "component---src-templates-page-sidebar-js": () => import("./../../../src/templates/page-sidebar.js" /* webpackChunkName: "component---src-templates-page-sidebar-js" */)
}

